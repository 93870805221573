<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span
        v-if="formState!==null"
        class="mt-4"
      >{{ formState }} Record</span>
      <template v-else>
        <v-btn
          icon
          small
          class="mt-4"
          text
          title="Add New"
          @click="formState='New'"
        >
          <v-icon>{{ icon.add }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          class="mt-4"
          text
          title="Upload"
          @click="openUploader=true"
        >
          <v-icon>{{ icon.upload }}</v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- employee -->
        <search-employee
          :employeeId="employee"
          :employeeNumber="employeeNumber"
          :disabled="formState===null"
          :errorMessage="formErrorFields.employeeErrorMessage"
          @outputId="setEmployeeId"
        ></search-employee>

        <!-- Date -->
        <v-dialog
          ref="dialog"
          v-model="modalDate"
          :return-value.sync="dateValue"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date"
              outlined
              dense
              placeholder="Date"
              v-bind="attrs"
              v-on="on"
              :append-icon="icon.calendar"
              :class="formErrorFields.dateErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.dateErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('date', formErrorFields)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-btn text color="primary" @click="modalDate=false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalDate=false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <!-- Time in -->
        <v-dialog
          ref="dialog"
          v-model="modalTimeIn"
          :return-value.sync="timeInValue"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="timeIn"
              label="Time in"
              outlined
              dense
              placeholder="Time in"
              v-bind="attrs"
              v-on="on"
              :append-icon="icon.clock"
              :class="formErrorFields.timeInErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.timeInErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('timeIn', formErrorFields)"
            ></v-text-field>
          </template>
          <v-time-picker v-model="timeIn" scrollable>
            <v-btn text color="primary" @click="modalTimeIn=false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalTimeIn=false">
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>

        <!-- Time in -->
        <v-dialog
          ref="dialog"
          v-model="modalTimeOut"
          :return-value.sync="timeOutValue"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="timeOut"
              label="Time out"
              outlined
              dense
              placeholder="Time out"
              v-bind="attrs"
              v-on="on"
              :append-icon="icon.clock"
              :class="formErrorFields.timeOutErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.timeOutErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('timeOut', formErrorFields)"
            ></v-text-field>
          </template>
          <v-time-picker v-model="timeOut" scrollable>
            <v-btn text color="primary" @click="modalTimeOut=false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalTimeOut=false">
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <file-uploader
      :show="openUploader"
      @closeDialog="openUploader=false"
      :accept="'.dat'"
      :uri="uploadUri"
    ></file-uploader>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiCalendarOutline,
  mdiClockOutline,
  mdiPlusOutline,
  mdiUploadOutline,
} from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import SearchEmployee from '@/components/SearchEmployee.vue'
import FileUploader from '@/components/FileUploader.vue'

export default {
  components: {
    snackbar,
    SearchEmployee,
    FileUploader,
  },
  props: ['data'],
  setup(props, { emit }) {
    const uploadUri = ref(`${process.env.VUE_APP_URI}/api/payroll/attendance-entries/upload-dat-file`)
    const openUploader = ref(false)
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      calendar: mdiCalendarOutline,
      clock: mdiClockOutline,
      upload: mdiUploadOutline,
    }
    const employee = ref(null)
    const employeeNumber = ref('')
    const dateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const timeInValue = ref(null)
    const timeOutValue = ref(null)
    const date = ref('')
    const timeIn = ref('')
    const timeOut = ref('')
    const modalDate = ref(false)
    const modalTimeIn = ref(false)
    const modalTimeOut = ref(false)
    const formState = ref(null)
    const formErrorFields = ref({
      employeeErrorMessage: '',
      timeInErrorMessage: '',
      timeOutErrorMessage: '',
      dateErrorMessage: '',
    })

    watch(() => props.data, () => {
      employee.value = props.data.employee_id
      employeeNumber.value = props.data.employee_number
      date.value = props.data.date
      timeIn.value = props.data.time_in_time
      timeOut.value = props.data.time_out_time
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      employee.value = null
      date.value = null
      timeIn.value = null
      timeOut.value = null
    }

    const submit = async () => {
      const formData = {
        employee: employee.value,
        date: date.value,
        timeIn: timeIn.value,
        timeOut: timeOut.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/attendance-entries`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/attendance-entries/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    const setEmployeeId = val => {
      employee.value = val
    }

    return {
      setEmployeeId,
      employee,
      employeeNumber,
      modalTimeIn,
      timeIn,
      timeInValue,
      modalTimeOut,
      timeOut,
      timeOutValue,
      modalDate,
      date,
      dateValue,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      openUploader,
      uploadUri,
    }
  },
}
</script>
