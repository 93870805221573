var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1"},[_c('v-card-title',[(_vm.formState!==null)?_c('span',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.formState)+" Record")]):[_c('v-btn',{staticClass:"mt-4",attrs:{"icon":"","small":"","text":"","title":"Add New"},on:{"click":function($event){_vm.formState='New'}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icon.add))])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-4",attrs:{"icon":"","small":"","text":"","title":"Upload"},on:{"click":function($event){_vm.openUploader=true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icon.upload))])],1)]],2),_c('v-card-text',[_c('v-form',[_c('search-employee',{attrs:{"employeeId":_vm.employee,"employeeNumber":_vm.employeeNumber,"disabled":_vm.formState===null,"errorMessage":_vm.formErrorFields.employeeErrorMessage},on:{"outputId":_vm.setEmployeeId}}),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dateValue,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dateValue=$event},"update:return-value":function($event){_vm.dateValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.formErrorFields.dateErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"label":"Date","outlined":"","dense":"","placeholder":"Date","append-icon":_vm.icon.calendar,"error-messages":_vm.formErrorFields.dateErrorMessage,"disabled":_vm.formState===null},on:{"click":function($event){_vm.formError().remove('date', _vm.formErrorFields)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalDate),callback:function ($$v) {_vm.modalDate=$$v},expression:"modalDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalDate=false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalDate=false}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.timeInValue,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.timeInValue=$event},"update:return-value":function($event){_vm.timeInValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.formErrorFields.timeInErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"label":"Time in","outlined":"","dense":"","placeholder":"Time in","append-icon":_vm.icon.clock,"error-messages":_vm.formErrorFields.timeInErrorMessage,"disabled":_vm.formState===null},on:{"click":function($event){_vm.formError().remove('timeIn', _vm.formErrorFields)}},model:{value:(_vm.timeIn),callback:function ($$v) {_vm.timeIn=$$v},expression:"timeIn"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalTimeIn),callback:function ($$v) {_vm.modalTimeIn=$$v},expression:"modalTimeIn"}},[_c('v-time-picker',{attrs:{"scrollable":""},model:{value:(_vm.timeIn),callback:function ($$v) {_vm.timeIn=$$v},expression:"timeIn"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalTimeIn=false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalTimeIn=false}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.timeOutValue,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.timeOutValue=$event},"update:return-value":function($event){_vm.timeOutValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.formErrorFields.timeOutErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"label":"Time out","outlined":"","dense":"","placeholder":"Time out","append-icon":_vm.icon.clock,"error-messages":_vm.formErrorFields.timeOutErrorMessage,"disabled":_vm.formState===null},on:{"click":function($event){_vm.formError().remove('timeOut', _vm.formErrorFields)}},model:{value:(_vm.timeOut),callback:function ($$v) {_vm.timeOut=$$v},expression:"timeOut"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalTimeOut),callback:function ($$v) {_vm.modalTimeOut=$$v},expression:"modalTimeOut"}},[_c('v-time-picker',{attrs:{"scrollable":""},model:{value:(_vm.timeOut),callback:function ($$v) {_vm.timeOut=$$v},expression:"timeOut"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalTimeOut=false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalTimeOut=false}}},[_vm._v(" OK ")])],1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.formState===null,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","disabled":_vm.formState===null},on:{"click":_vm.reset}},[_vm._v(" Cancel ")])],1)],1),(_vm.responseMessage.length > 0)?_c('snackbar',{attrs:{"show":_vm.responseMessage.length > 0,"text":_vm.responseMessage,"color":_vm.responseMessageStatus},on:{"close":function($event){_vm.responseMessage=''}}}):_vm._e(),_c('file-uploader',{attrs:{"show":_vm.openUploader,"accept":'.dat',"uri":_vm.uploadUri},on:{"closeDialog":function($event){_vm.openUploader=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }